export const LOADING_CONTACT_FORM = 'LOADING_CONTACT_FORM';
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS';
export const CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR';
export const SUBMITTING_EMAIL_FORM = 'SUBMITTING_EMAIL_FORM';
export const EMAIL_FORM_SUCCESS = 'EMAIL_FORM_SUCCESS';
export const EMAIL_FORM_ERROR = 'EMAIL_FORM_ERROR';
export const LOADING_PLAYLIST = 'LOADING_PLAYLIST';
export const PLAYLIST_CURRENT = 'PLAYLIST_CURRENT';
export const PLAYLIST_LAST_PLAYED = 'PLAYLIST_LAST_PLAYED';
export const PLAYLIST_ERROR = 'PLAYLIST_ERROR';
export const SUBMITTING_SIGNUP_FORM = 'SUBMITTING_SIGNUP_FORM';
export const SIGNUP_FORM_SUCCESS = 'SIGNUP_FORM_SUCCESS';
export const SIGNUP_FORM_ERROR = 'SIGNUP_FORM_ERROR';
