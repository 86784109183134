import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import contactFormReducer from './contact-form';
import emailFormReducer from './email-form';
import playlistReducer from './playlist';
import signUpFormReducer from './sign-up-form';

const CustomerConnect = combineReducers({
    form: formReducer,
    contactFormState: contactFormReducer,
    emailFormState: emailFormReducer,
    playlistState: playlistReducer,
    signUpFormState: signUpFormReducer
});

export default CustomerConnect;
