import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import CustomerConnect from './reducers/customer-connect';


const middleware = [thunk];

const store = createStore(
    CustomerConnect,
    applyMiddleware(...middleware)
);

export default ({ element }) => (
    <Provider store={store}>{element}</Provider>
);
