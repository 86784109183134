import * as types from '../constants/actiontypes';

const initialState = {
    isSubmitting: false,
    signUpFormSuccess: false,
    signUpFormSuccessMessage: '',
    errors: []
};

const signUpFormReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.SUBMITTING_SIGNUP_FORM:
        return Object.assign({}, state, {
            isSubmitting: true
        });
    case types.SIGNUP_FORM_SUCCESS:
        return Object.assign({}, state, {
            signUpFormSuccess: true,
            signUpFormSuccessMessage: action.message,
            isSubmitting: false
        });
    case types.SIGNUP_FORM_ERROR:
        return Object.assign({}, state, {
            errors: action.errors,
            isSubmitting: false
        });
    default:
        return state;
    }
};

export default signUpFormReducer;
