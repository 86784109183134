import * as types from '../constants/actiontypes';

const initialState = {
    isLoading: true,
    currentTrack: {},
    lastPlayed: [],
    noPlayer: false
};

const playlistReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.LOADING_PLAYLIST:
        return Object.assign({}, state, {
            isLoading: true
        });
    case types.PLAYLIST_CURRENT:
        return Object.assign({}, state, {
            currentTrack: action.data,
            isLoading: false
        });
    case types.PLAYLIST_LAST_PLAYED:
        return Object.assign({}, state, {
            lastPlayed: action.data,
            isLoading: false
        });
    case types.PLAYLIST_ERROR:
        return Object.assign({}, state, {
            isLoading: false,
            noPlayer: true
        });
    default:
        return state;
    }
};

export default playlistReducer;
