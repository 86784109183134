// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-pop-golf-connect-js": () => import("./../src/pages/pop-golf-connect.js" /* webpackChunkName: "component---src-pages-pop-golf-connect-js" */),
  "component---src-pages-pop-golf-landing-js": () => import("./../src/pages/pop-golf-landing.js" /* webpackChunkName: "component---src-pages-pop-golf-landing-js" */)
}

