import * as types from '../constants/actiontypes';

const initialState = {
    isLoading: true,
    isSubmitting: false,
    contactFormSuccess: false,
    errors: []
};

const contactFormReducer = (state = initialState, action) => {
    switch (action.type) {
    case types.LOADING_CONTACT_FORM:
        return Object.assign({}, state, {
            isSubmitting: true
        });
    case types.CONTACT_FORM_SUCCESS:
        return Object.assign({}, state, {
            contactFormSuccess: true,
            isLoading: false,
            isSubmitting: false
        });
    case types.CONTACT_FORM_ERROR:
        return Object.assign({}, state, {
            errors: action.errors,
            isLoading: false,
            isSubmitting: false
        });
    default:
        return state;
    }
};

export default contactFormReducer;
